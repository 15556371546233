<template>
  <div class="container">
    <div class="work-container">
      <div class="image-wrap">
        <div class="image-list">
          <div :class="['image-item' ,activeIndex === index ? 'image-active':'' ,item.isModify ? 'image-save':'']"
               v-for="(item,index) in imageList"
               :key="index" @click="imageClick(item.url,index)"><img :src="item.url" alt="" class="image"></div>
        </div>
      </div>
      <div class="wrap">
        <div class="btn-wrap">
          <el-button @click="saveHandle">保存</el-button>
          <el-button type="primary" @click="submitImage">提交</el-button>
        </div>
        <div class="work-image">
          <canvas id="canvas_admin" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Mosaic from "image-mosaic";
import {getQueryVariable} from "@/libs/util";

export default {
  name: "processing",
  data() {
    return {
      isMosaic: false,
      imageList: [
        {
          isModify: false,
          url: ''
        },

      ],
      editImgUrl: '',
      activeIndex: '',
      id: '',
      userId: '',
      userName: '',
    }
  },
  created() {
    let id = getQueryVariable('id');
    if (!id) {
      this.$message('获取信息失败')
      return;
    }
    let userId = getQueryVariable('userId') || '';
    let userName = getQueryVariable('userName') || '';
    this.id = id;
    this.userId = userId;
    this.userName = userName;
    this.getCaseRecords(id)
  },
  methods: {
    getCaseRecords(id) {
      this.$ajaxApi.adminApi.getCaseRecords(id)
          .then(res => {
            if (res.data.status_code === 200) {
              this.imageList = res.data.data.coding_image_list.map(item => {
                return {
                  url: item,
                  isModify: false,
                }
              })
              // this.$message.success(res.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
    },
    //图片点击
    imageClick(url, index) {
      this.activeIndex = index;
      this.getBase64(url,dataURL => {

          this.editImgUrl = dataURL
          this.isMosaic = true;
          this.initMosaic();
      });
      // console.log(baseUrl)
    },
    // getBase64(imgUrl) {
    //   let that = this;
    //   window.URL = window.URL || window.webkitURL;
    //   let xhr = new XMLHttpRequest();
    //   xhr.open("get", imgUrl, true);
    //   xhr.responseType = "blob";
    //   xhr.onload = function () {
    //     console.log(this.status)
    //     if (this.status === 200) {
    //       //得到一个blob对象
    //       let blob = this.response;
    //       // 至关重要
    //       let oFileReader = new FileReader();
    //       oFileReader.onloadend = function (e) {
    //         // 此处拿到的已经是base64的图片了,可以赋值做相应的处理
    //         that.editImgUrl = e.target.result
    //         that.isMosaic = true;
    //         that.initMosaic();
    //
    //       }
    //       oFileReader.readAsDataURL(blob);
    //     }
    //   }
    //   xhr.send();
    // },
    // getBase64(imgUrl) {
    //   let img = new Image();
    //   img.src = imgUrl;
    //   // let base64Img = getBase64Image(img);
    //   // console.log(base64Img);
    //   var canvas = document.createElement("canvas");
    //   canvas.width = img.width;
    //   canvas.height = img.height;
    //   var ctx = canvas.getContext("2d");
    //   ctx.drawImage(img, 0, 0, img.width, img.height);
    //   var ext = img.src.substring(img.src.lastIndexOf(".") + 1).toLowerCase();
    //   var dataURL = canvas.toDataURL("image/" + ext);
    //   console.log(dataURL)
    //   return
    //   this.editImgUrl = dataURL
    //   this.isMosaic = true;
    //   this.initMosaic();
    //   return dataURL;
    //   //将url转化为Base64
    // },
    getBase64(url,callback) {
      var Img = new Image(),
          dataURL = '';
      Img.src = url + '?v=' + Math.random();
      Img.setAttribute('crossOrigin', 'Anonymous');
      Img.onload = function() {
        var canvas = document.createElement('canvas'),
            width = Img.width,
            height = Img.height;
        canvas.width = width;
        canvas.height = height;
        canvas.getContext('2d').drawImage(Img, 0, 0, width, height);
        dataURL = canvas.toDataURL('image/jpeg');
        return callback ? callback(dataURL) : null;
      };
    },

    drawImageToCanvas(imageUrl) {
      const canvas = document.querySelector('#canvas_admin')
      const ctx = canvas.getContext('2d')

      return new Promise((resolve, reject) => {
        const image = new Image()
        image.crossOrigin = 'Annoymous'
        image.onload = function () {
          let img_width = image.width
          let img_height = image.height
          let w_width = document.body.clientWidth;
          if(img_width > w_width){
            img_width = w_width - 250
          }
          if(img_height > 900){
            img_height = 900
          }
          canvas.width = img_width
          canvas.height = img_height
          ctx.drawImage(this, 0, 0, img_width, img_height)
          resolve(ctx)
        }
        image.src = imageUrl
      })
    },

    initMosaic() {
      this.drawImageToCanvas(this.editImgUrl).then((ctx) => {
        const mosaic = new Mosaic(ctx)
        console.log(mosaic)
        //alert('888')
        const MouseEvents = {
          init() {
            mosaic.context.canvas.addEventListener(
                'mousedown',
                MouseEvents.mousedown
            )
          },
          mousedown() {
            mosaic.context.canvas.addEventListener(
                'mousemove',
                MouseEvents.mousemove
            )

            document.addEventListener('mouseup', MouseEvents.mouseup)
          },
          mousemove(e) {
            if (e.shiftKey) {
              mosaic.eraseTileByPoint(e.layerX, e.layerY)
              return
            }
            mosaic.drawTileByPoint(e.layerX, e.layerY)
          },

          mouseup() {
            mosaic.context.canvas.removeEventListener(
                'mousemove',
                MouseEvents.mousemove
            )

            document.removeEventListener('mouseup', MouseEvents.mouseup)
          }
        }
        MouseEvents.init()
      })
    },
    saveHandle() {
      this.$loading()
      if (this.activeIndex === '') {
        this.$message.error('请选择保存图片');
        return;
      }
      let localData = document.querySelector('#canvas_admin').toDataURL('image/png')

      let formData = new FormData();
      let fileName = new Date().getTime() + ".jpeg";
      formData.append("file", this.dataURLtoFile(localData, fileName))
      // console.log(formData)
      this.uploadFile(formData)
      console.log(this.imageList)
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(','),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, {type: mime});
    },
    submitImage() {
      if (!this.id) {
        this.$message('获取ID信息失败');
        return;
      }
      let coding_image_list = []
      this.imageList.forEach(item => {
        console.log(item)
        coding_image_list.push(item.url)
      })
      let id = this.id;
      let data = {
        coding_image_list: coding_image_list,
        userId:parseInt(this.userId),
        userName:this.userName,
      }
      this.$ajaxApi.adminApi.putCaseRecords(id, data)
          .then(res => {
            if (res.data.status_code === 200) {
              this.$message.success(res.data.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            console.log(error)
          })
    },

    uploadFile(formData) {
      // let localData = document.querySelector('#canvas').toDataURL('image/png')

      // let data = {
      //   file
      // }
      this.$ajaxApi.adminApi.uploadFile(formData)
          .then(res => {
            this.$loading().close();
            if (res.data.status_code === 200) {
              this.imageList[this.activeIndex].url = res.data.data.url;
              this.imageList[this.activeIndex].isModify = true;
              this.$message.success(res.data.data.message);
            } else {
              this.$message.error(res.data.message);
            }
          })
          .catch(error => {
            this.$loading().close();
            console.log(error)
          })
    },
  }
}
</script>

<style scoped>
.work-container {
  width: 95%;
  margin: 0 auto;
  padding: 15px;
  display: flex;
}

.image-list {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: 800px;
  overflow-y: scroll;
  /*解决ios上滑动不流畅*/
  -webkit-overflow-scrolling: touch;
  padding-bottom: 25px;
  padding-right: 20px;
  justify-content: center;

}

.image-list::-webkit-scrollbar {
  display: none
}

.image-wrap {
  overflow: hidden;
  width: 150px;
  min-width: 150px;
}

.image-item {
  margin: 15px 0;
  border: 5px solid #FFFFFF;
  border-radius: 2px;
  transition: all 0.3s;
  height: 160px;
  width: 130px;
}

.image-active {
  border: 5px solid #44A4E6;
}

.image-save {
  border: 5px solid #47AE27;
}

.image {
  width: 100%;
  height: 100%;
}

.wrap {
  width: 85%;
  text-align: center;
  border: 1px solid #ddd;
  margin: 0 15px;
  padding: 15px;
  border-radius: 5px;
}

.btn-wrap {
  text-align: right;
  padding-bottom: 20px;
}
.work-image{
  width: 100%;
  height: 100%;
  /*overflow-x: scroll;*/
}
</style>

